import {Button, ButtonGroup, Modal} from '@nike/eds';
import './ConfirmationModal.css';

type props = {
    isModalVisible: boolean
    setIsModalVisible: (isVisible: boolean) => void
    selectedDates: string[]
}

export function ConfirmationModal({isModalVisible, setIsModalVisible, selectedDates}: props) {
    return (
        <Modal
            isOpen={isModalVisible}
            onDismiss={() => setIsModalVisible(!isModalVisible)}
            headerSlot="Submit Batch"
            footerSlot={
                <ButtonGroup>
                    <Button onClick={() => console.log('pressed')} size="small">
                        Submit
                    </Button>
                    <Button
                        onClick={() => setIsModalVisible(!isModalVisible)}
                        size="small"
                        variant="secondary"
                    >
                        Cancel
                    </Button>
                </ButtonGroup>
            }
        >
            <div style={{minWidth: "500px"}}>
                <p className="eds-type--body-2">You are about to submit the following batches:</p>
                <ul id="date_list">
                    {selectedDates.sort((a,b) => new Date(a).getTime() - new Date(b).getTime()).map(date => (
                        <li className="horizontal-list-item eds-type--body-3" key={date}>{date}</li>
                    ))}
                </ul>
            </div>
        </Modal>
    )
}