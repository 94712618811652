import {Select} from '@nike/eds';
import './MonthSelect.css';

export function MonthSelect() {
    const getPreviousMonthDate = (date: Date) => {
        const clone = new Date(date);
        clone.setDate(0);
        return clone;
    }
    const currentDate = new Date();

    const availableMonths = [
        { value: currentDate, label: `${currentDate.getFullYear()} - ${currentDate.getMonth() + 1}`},
        { value: getPreviousMonthDate(currentDate), label: `${getPreviousMonthDate(currentDate).getFullYear()} - ${getPreviousMonthDate(currentDate).getMonth() + 1}`},
    ];

    return (
        <div id="month-select-container">
            <Select
                id="month-select"
                options={availableMonths}
                defaultValue={availableMonths[0]}
                label="Select month"
                onChange={selected => {
                    console.log("Changed Date to:", selected?.value)
                }}
            />
        </div>

    )
}