import {Checkbox, Table, TableCell, TableHeading} from '@nike/eds';
import {createColumnHelper, flexRender, getCoreRowModel, useReactTable} from '@tanstack/react-table';
import {LocalClearanceBatch} from '../../shared/Types';
import {BatchStatusIndicator} from '../batch_status_indicator/BatchStatusIndicator';
import './BatchTable.css';

type props = {
    data: LocalClearanceBatch[]
    selectedDates: string[]
    allDatesSelected: boolean
    handleAllSelection: () => void
    handleDateSelection: (date: string) => void
}

export function BatchTable({data, selectedDates, handleAllSelection, allDatesSelected, handleDateSelection}: props) {
    const columnHelper = createColumnHelper<LocalClearanceBatch>();
    const columns = [
        columnHelper.display({
            id: 'select_batch',
            header: () => <Checkbox id="check-all" title="check-all" label="" checked={allDatesSelected} onChange={handleAllSelection} />,
            cell: (props) =>  {
                const localClearanceBatch = props.row.original
                return localClearanceBatch.status === "NEW"
                ? <Checkbox id={props.row.id} label="" value={localClearanceBatch.day_bucket_date} checked={selectedDates.indexOf(localClearanceBatch.day_bucket_date) > -1} onChange={e => handleDateSelection(e.target.value)}/>
                : null
            },
            minSize: 0,
            maxSize: 25,
        }),
        columnHelper.accessor("day_bucket_date", {
            header: "EiDR batch",
            id: "eidr_batch",
            minSize: 0,
            maxSize: 50,
        }),
        columnHelper.accessor("user_id", {
            header: "Released by",
            id: "released_by",
            minSize: 0,
            maxSize: 50,
        }),
        columnHelper.accessor("status", {
            header: "Release status",
            id: "release_status",
            cell: props => <BatchStatusIndicator {...props.row.original}/>
        })
    ];

    const {getHeaderGroups, getRowModel} = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel()
    });

    return (
        <Table id="batch-table">
            <thead>
            {getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header, index) => (
                            <TableHeading
                                key={header.id}
                                style={{
                                    minWidth: header.column.columnDef.minSize,
                                    maxWidth: header.column.columnDef.maxSize
                                }}
                                className={`eds-type--subtitle-1 eds-color--default ${index === 0 ? "table-action-header" : "table-header"}`}
                            >
                            {flexRender(header.column.columnDef.header, header.getContext())}
                        </TableHeading>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody>
            {getRowModel().rows.map(row => (
                <tr key={row.id}>
                    {row.getVisibleCells().map((cell, index) => (
                        <TableCell
                            key={cell.id}
                            className={`eds-type--body-3 ${index === 0 ? "table-action-data" : "table-data"}`}
                            style={{
                                minWidth: cell.column.columnDef.minSize,
                                maxWidth: cell.column.columnDef.maxSize
                            }}
                        >
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                    ))}
                </tr>
            ))}
            </tbody>
        </Table>
    )
}