import {Button} from '@nike/eds';
import {MonthSelect} from '../components/month_select/MonthSelect';
import {BatchTable} from '../components/batch_table/BatchTable';
import {ConfirmationModal} from '../components/confirmation_model/ConfirmationModal';
import {useMemo, useState} from 'react';
import mockBatches from '../mock/mock-batches.json';
import {LocalClearanceBatch} from '../shared/Types';
import './local-clearance-overview.css';


export function LocalClearanceOverview() {
    const [allDatesSelected, setAllDatesSelected] = useState<boolean>(false);
    const [selectedDates, setSelectedDates] = useState<string[]>([]);
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);


    // TODO: When integrating with GraphQL, please use the useEffect hook to fill in the missing batches
    const fillMissingDates = (batches: LocalClearanceBatch[]) => {
        const enrichedBatches: LocalClearanceBatch[] = [];
        // const currentDate = new Date()
        const currentDate = new Date(2024, 9, 31);
        const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 2);
        const endDate = new Date(currentDate);
        endDate.setDate(currentDate.getDate() - 1);

        for (let indexDate = startDate; indexDate <= endDate; indexDate.setDate(indexDate.getDate() + 1)) {
            if (batches.find(batch => batch.day_bucket_date === indexDate.toISOString().split('T')[0])) {
                enrichedBatches.push(batches.find(batch => batch.day_bucket_date === indexDate.toISOString().split('T')[0]) as LocalClearanceBatch);
            } else {
                enrichedBatches.push({
                    day_bucket_date: indexDate.toISOString().split('T')[0],
                    status: 'NEW'
                });
            }
        }
        return enrichedBatches;
    }
    const enrichedBatches = useMemo(() => fillMissingDates(mockBatches), []);

    const handleAllSelection = () => {
        if (allDatesSelected) {
            setAllDatesSelected(false);
            setSelectedDates([]);
        } else {
            setAllDatesSelected(true);
            setSelectedDates(enrichedBatches.filter(batch => batch.status === 'NEW').map(batch => batch.day_bucket_date));
        }
    }

    const handleDateSelection = (date: string) => {
        const currentSelectedDates = [...selectedDates];
        if (currentSelectedDates.indexOf(date) > -1) {
            currentSelectedDates.splice(currentSelectedDates.indexOf(date), 1);
        } else {
            currentSelectedDates.push(date);
        }
        setSelectedDates(currentSelectedDates);
    }


    return (
        <div id="local-clearance-overview">
            <MonthSelect />
            <BatchTable
                data={enrichedBatches}
                {...{allDatesSelected, selectedDates, handleAllSelection, handleDateSelection}}
            />
            <Button onClick={() => setIsModalVisible(true)} disabled={selectedDates.length === 0}>
                Release selected batches for declaration
            </Button>
            <ConfirmationModal {...{isModalVisible, setIsModalVisible, selectedDates}}/>
        </div>
    )
}