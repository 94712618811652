import React from 'react';
import {Route, Routes} from 'react-router-dom';
import './App.css';
import {LocalClearanceOverview} from './view/LocalClearanceOverview';

function App() {
  return (
    <div id="app_container">
      <Routes>
          <Route path="/local-clearance" element={<LocalClearanceOverview />} />
      </Routes>
    </div>
  )
}

export default App
